import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"

const Header = ({ siteTitle }) => (
  <header className="header">
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" href="#!">
            <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.986 46">
              <g fill="#3a223a">
                <text font-family="Nunito" font-size="34" font-weight="600" transform="translate(34.986 34)">
                  <tspan x="0" y="0">css-gems</tspan>
                </text>
                <path d="M13.23 41l-1.159-1.909A74.01 74.01 0 001.04 24.829L0 23.847l1.048-.973A67.662 67.662 0 0012.064 8.632l1.139-1.921 1.159 1.909a74.012 74.012 0 0011.031 14.261l1.04.982-1.048.973a67.662 67.662 0 00-11.016 14.242zM3.882 23.865a78.35 78.35 0 019.322 11.981 70.006 70.006 0 019.348-12 78.35 78.35 0 01-9.322-11.981 70.008 70.008 0 01-9.348 12z"/>
              </g>
            </svg>
          </Link>
          <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className="ml-6 navbar-menu">
          <div className="navbar-start">
            <Link to="/box-shadow" className="navbar-item">
              Box-Shadow
            </Link>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
